/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/BasicPage";
import resolveImage from "@dataResolvers/resolveImage";
import resolveContentBuilder from "@dataResolvers/resolveContentBuilder";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query PageBasicQuery($uri: [String]) {
    craft {
      entry(section: "page", uri: $uri) {
        ... on Craft_page_basic_Entry {
          uid
          label: heading0
          # content
          contentBuilder {
            ...ContentBuilderFragment
          }
          # meta
          title
          url
          metaTitle
          metaImage {
            ...ImageFragment
          }
          metaDescription
          hideNewsletter: boolean4
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    label,
    // content
    contentBuilder,
    // meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    hideNewsletter,
  } = entry;

  return {
    title,
    label,
    contentBuilder: resolveContentBuilder(contentBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
    hideNewsletter,
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="PageBasicQuery"
    {...props}
  />
);

export default Page;
