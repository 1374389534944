import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const PageBody = loadable(() => import("@organisms/PageBody"));
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const BasicPage = ({ data }) => {
  const { label, title, contentBuilder, hideNewsletter } = data;
  return (
    <PageContainer overflow>
      <PageBody label={label} title={title} contentBuilder={contentBuilder} />
      {!hideNewsletter && <NewsletterSignup padding />}
    </PageContainer>
  );
};

export default BasicPage;
